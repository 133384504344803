<script lang="ts" setup>
  import { Banner } from '@bt/design-system'
  import type IBanner from '@/types/Storyblok/General/Banner'
  import type { icons } from '@/types/General/Icons'

  const props = defineProps<{
    blok: IBanner
    bannerIcon?: icons
  }>()

  const iconName = computed(() => props.blok.icon || props.bannerIcon)
</script>

<template>
  <div class="banner-wrapper">
    <Banner
      :bg="props.blok?.background"
      :text="props.blok.text"
      :title="props.blok?.title"
      action-position="center"
      full-text
    >
      <template
        v-if="iconName"
        #illustration
      >
        <i class="md-icon md-48 md-icon-fill text-bt-brand-green"> {{ iconName }} </i>
      </template>
      <template
        v-if="props.blok.button"
        #action="{ className }"
      >
        <GeneralButtonWrapper
          :buttons="props.blok.button"
          :class-name="className"
        />
      </template>
    </Banner>
    <BlockDivider v-if="props.blok.showDivider" />
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .banner-wrapper {
    margin-bottom: rem(32);
  }

  .link-wrap {
    width: 100%;
    min-width: rem(135);

    @include desktop-up {
      max-width: max-content;
    }
  }
</style>

