<script setup lang="ts">
  const config = useRuntimeConfig()
  const { locale } = useI18n()

  const props = defineProps<{
    buttons: any
    className: string
  }>()

  const getPath = (href: any) => {
    if (href && href.story) {
      const external = href.story?.full_slug.includes('company-airbaltic-com')
      let path = formatSbPath(href.story?.full_slug, locale.value)
      if (external) {
        path = config.public.companyUrl + '/' + locale.value + '/' + path
      }

      return formatSbPath(path, locale.value)
    }

    if (href.url) {
      return href.url
    }
  }
</script>

<template>
  <div
    v-for="button in props.buttons"
    class="link-wrap"
  >
    <StoryblokComponent
      v-if="button.component === 'ButtonDownload'"
      :key="button.id"
      :blok="button"
      :class="className"
    />
    <NuxtLink
      v-else
      :to="getPath(button?.href)"
    >
      <StoryblokComponent
        :key="button.id"
        :blok="button"
        :class="className"
      />
    </NuxtLink>
  </div>
</template>

<style scoped lang="scss">
  @import '@/assets/styles/common.scss';

  .link-wrap {
    width: 100%;
    min-width: rem(135);

    @include desktop-up {
      max-width: max-content;
    }
  }
</style>
